import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {t} from 'i18next';
import {EndPoints, JobStatusType} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, Observable, of, tap} from 'rxjs';
import {
  catchError,
  delay,
  filter,
  ignoreElements,
  mergeMap,
} from 'rxjs/operators';
import {WellniteAudiosService} from 'services';

import {wellniteAudiosActions} from './wellniteAudiosSlice';

const cloneVoiceEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.cloneVoice.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.cloneVoice(payload)).pipe(
        mergeMap(res =>
          of(wellniteAudiosActions.cloneVoiceSuccess(res.data.message)).pipe(
            tap(() => onSuccess?.(res.data.message)),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.cloneVoiceFailure(message)),
          ),
        ),
      ),
    ),
  );

const cloneWellniteAudiosFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.cloneVoiceFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteAudios.errorCloning'),
      });
    }),
    ignoreElements(),
  );

const generateAudioScriptEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.generateAudioScript.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.generateAudioScript(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteAudiosActions.generateAudioScriptSuccess(res.data.message),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.generateAudioScriptFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateAudioScriptFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.generateAudioScriptFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteAudios.errorGenerating'),
      });
    }),
    ignoreElements(),
  );

const POLLING_INTERVAL = 10000; // 10 seconds
const MAX_DURATION = 120000; // 2 minutes

const convertToAudioEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.convertToAudio.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.convertToAudio(payload)).pipe(
        mergeMap(res => {
          const jobId = res.data.message.jobId;

          const polling: Observable<any> = pollJobStatus(
            jobId,
            EndPoints.SpeechGenerationStatus,
            onSuccess,
          ).pipe(
            catchError((message: string) =>
              concat(
                of(
                  userActions.setAsyncError({
                    filter: 'wellniteAudios',
                    message,
                  }),
                ),
                of(wellniteAudiosActions.convertToAudioFailure(message)),
              ),
            ),
          );

          return concat(
            of(wellniteAudiosActions.convertToAudioSuccess(res.data.message)),
            polling,
          );
        }),
      ),
    ),
  );

const pollJobStatus = (
  jobId: string,
  endpoint: JobStatusType,
  onSuccess?: (message: any) => void,
) => {
  const startTime = Date.now();

  const poll = (): Observable<any> => {
    return from(WellniteAudiosService.getJobStatus({jobId, endpoint})).pipe(
      mergeMap(jobStatusRes => {
        const state = jobStatusRes.data.message.state;

        if (state !== 'completed') {
          const elapsedTime = Date.now() - startTime;
          if (elapsedTime < MAX_DURATION) {
            return concat(
              of(
                wellniteAudiosActions.getJobStatusSuccess({
                  ...jobStatusRes.data.message,
                  type: endpoint,
                }),
              ).pipe(delay(POLLING_INTERVAL)),
              poll(),
            );
          } else {
            return of(
              wellniteAudiosActions.getJobStatusFailure('Polling timed out'),
            );
          }
        } else {
          onSuccess?.(jobStatusRes.data.message);
          return of(
            wellniteAudiosActions.getJobStatusSuccess({
              ...jobStatusRes.data.message,
              type: endpoint,
            }),
          );
        }
      }),
      catchError(jobStatusMessage =>
        of(wellniteAudiosActions.getJobStatusFailure(jobStatusMessage)),
      ),
    );
  };

  return poll(); // Start the polling process
};

const convertToAudioFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.convertToAudioFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteAudios.errorConverting'),
      });
    }),
    ignoreElements(),
  );

const regenerateAudioParagraphEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.regenerateAudioParagraph.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.regenerateAudioParagraph(payload)).pipe(
        mergeMap(res => {
          const jobId = res.data.message.jobId;

          const polling: Observable<any> = pollJobStatus(
            jobId,
            EndPoints.RegenerateParagraphStatus,
            onSuccess,
          ).pipe(
            catchError((message: string) =>
              concat(
                of(
                  userActions.setAsyncError({
                    filter: 'wellniteAudios',
                    message,
                  }),
                ),
                of(
                  wellniteAudiosActions.regenerateAudioParagraphFailure(
                    message,
                  ),
                ),
              ),
            ),
          );

          return concat(
            of(
              wellniteAudiosActions.regenerateAudioParagraphSuccess(
                res.data.message,
              ),
            ),
            polling,
          );
        }),
      ),
    ),
  );

const regenerateAudioParagraphFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.regenerateAudioParagraphFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteAudios.errorRegenerating'),
      });
    }),
    ignoreElements(),
  );

const regenerateCoverImageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.regenerateCoverImage.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.regenerateCoverImage(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteAudiosActions.regenerateCoverImageSuccess(res.data.message),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.regenerateCoverImageFailure(message)),
          ),
        ),
      ),
    ),
  );

const regenerateCoverImageFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.regenerateCoverImageFailure.match),
    tap(() => {
      Toast({
        type: 'warning',
        message: t('wellniteAudios.errorRegeneratingCoverImage'),
      });
    }),
    ignoreElements(),
  );

const generateAudioSuggestionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.generateAudioSuggestions.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.generateAudioSuggestions(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteAudiosActions.generateAudioSuggestionsSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.generateAudioSuggestionsFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteAudiosEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.getAudios.match),
    mergeMap(({payload}) =>
      from(WellniteAudiosService.getAudios(payload)).pipe(
        mergeMap(res =>
          of(wellniteAudiosActions.getAudiosSuccess(res.data.message)),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.getAudiosFailure(message)),
          ),
        ),
      ),
    ),
  );

const getWellniteAudiosFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.getAudiosFailure.match),
    tap(({payload}) => {
      Toast({
        type: 'warning',
        message: payload,
      });
    }),
    ignoreElements(),
  );

const submitAudioEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.submitAudio.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.submitAudio(payload)).pipe(
        mergeMap(res =>
          of(wellniteAudiosActions.submitAudioSuccess(res.data.message)).pipe(
            tap(() => onSuccess?.(res.data.message)),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.submitAudioFailure(message)),
          ),
        ),
      ),
    ),
  );

const regenerateAudioTrackEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.regenerateAudioTrack.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.regenerateAudioTrack(payload)).pipe(
        mergeMap(res => {
          const jobId = res.data.message.jobId;

          const polling: Observable<any> = pollJobStatus(
            jobId,
            EndPoints.RegenerateTrackStatus,
            onSuccess,
          ).pipe(
            catchError((message: string) =>
              concat(
                of(
                  userActions.setAsyncError({
                    filter: 'wellniteAudios',
                    message,
                  }),
                ),
                of(wellniteAudiosActions.regenerateAudioTrackFailure(message)),
              ),
            ),
          );

          return concat(
            of(
              wellniteAudiosActions.regenerateAudioTrackSuccess(
                res.data.message,
              ),
            ),
            polling,
          );
        }),
      ),
    ),
  );

const deleteAudioEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.deleteAudio.match),
    mergeMap(({payload}) =>
      from(
        WellniteAudiosService.deleteAudio(payload.audioId, payload.onSuccess),
      ).pipe(
        mergeMap(_ =>
          of(
            wellniteAudiosActions.deleteAudioSuccess({
              audioId: payload.audioId,
            }),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.deleteAudioFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateAudioCoverImageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.generateAudioCoverImage.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(WellniteAudiosService.generateAudioCoverImage(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteAudiosActions.generateAudioCoverImageSuccess(
              res.data.message,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.generateAudioCoverImageFailure(message)),
          ),
        ),
      ),
    ),
  );

const getJobStatusEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.getJobStatus.match),
    mergeMap(({payload}) =>
      from(WellniteAudiosService.getJobStatus(payload)).pipe(
        mergeMap(res =>
          of(
            wellniteAudiosActions.getJobStatusSuccess({
              ...res.data.message,
              type: payload.endpoint,
            }),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'wellniteAudios',
                message,
              }),
            ),
            of(wellniteAudiosActions.getJobStatusFailure(message)),
          ),
        ),
      ),
    ),
  );

const getJobStatusFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(wellniteAudiosActions.getJobStatusFailure.match),
    tap(({payload}) => {
      Toast({
        type: 'warning',
        message: payload,
      });
    }),
    ignoreElements(),
  );

export const wellniteAudiosEpics = [
  cloneVoiceEpic,
  cloneWellniteAudiosFailureEpic,
  generateAudioScriptEpic,
  generateAudioScriptFailureEpic,
  convertToAudioEpic,
  convertToAudioFailureEpic,
  regenerateAudioParagraphEpic,
  regenerateAudioParagraphFailureEpic,
  regenerateCoverImageEpic,
  regenerateCoverImageFailureEpic,
  generateAudioSuggestionEpic,
  getWellniteAudiosEpic,
  getWellniteAudiosFailureEpic,
  submitAudioEpic,
  regenerateAudioTrackEpic,
  deleteAudioEpic,
  generateAudioCoverImageEpic,
  getJobStatusEpic,
  getJobStatusFailureEpic,
].flatMap(epic => epic);
